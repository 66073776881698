import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './quickShop.css';

const QuickShop = () => (
  <section id="quickshop">
    <div className="container">
      <div className="row braveshop d-flex align-items-center justify-content-between">
        <div className="col-lg-4 col-md-4">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdz3H2fq2hRqIpu_MKYS18qSXn1cZdqDHNH3BUqg0uwSdWn7A/viewform">
            <div className="box">
              <StaticImage
                src="../../images/shop/white-shirt.jpeg"
                className="image img-fluid"
                alt="Sheisbrave white t-shirt"
              />
            </div>
          </a>
        </div>

        <div className="col-lg-4 col-md-4">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdO43idz23wxECe3WYP5tkjINXbSl6VxCx_2jHTxTA-nWeZ0w/viewform">
            <div className="box">
              <StaticImage
                src="../../images/shop/white-hoodie.jpg"
                className="img-fluid"
                alt="sheisbrave white Hoodie"
              />
            </div>
          </a>
        </div>

        <div className="col-lg-4 col-md-4">
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc80trn66hCmepVK8QufFzh2UbVZXGbwIZNq_T1bToK2Z7pAA/viewform">
            <div className="box">
              <StaticImage
                src="../../images/shop/white-cap.jpg"
                className="img-fluid"
                alt="Sheisbrave white cap"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default QuickShop;
