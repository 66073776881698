import * as React from 'react';
import Layout from '../Components/Layout/Layout';
import QuickShop from '../Components/QuickShop/QuickShop';
import SEO from '../Components/seo';
// markup
const ShopPage = () => (
  <>
    <SEO title="Shop" />
    <Layout page="Shop">
      <main id="main">
        <QuickShop />
      </main>
    </Layout>
  </>

);

export default ShopPage;
